import type { CartItem } from "~/models/CartItem"
import type { ShopCart, ShopCartStoreItem } from "~/models/ShopCart"

export const useShopCartStore = defineStore("shop_cart_store", {
    state: (): {
        shopCart: ShopCart
        loadingShopCart: boolean
    } => ({
        shopCart: {
            count: 0,
            subtotal: 0,
            shipment_subtotal: 0,
            to_pay: 0,
            tax: 0,
            subtotal_formatted: "",
            shipment_formatted: "",
            to_pay_formatted: "",
            tax_formatted: "",
            is_wholesaler: false,
            stores_data: [],
        },
        loadingShopCart: false,
    }),
    actions: {
        async clearCart() {
            this.shopCart = {
                count: 0,
                subtotal: 0,
                shipment_subtotal: 0,
                to_pay: 0,
                tax: 0,
                subtotal_formatted: "",
                shipment_formatted: "",
                to_pay_formatted: "",
                tax_formatted: "",
                is_wholesaler: false,
                stores_data: [],
            }
        },
        async fetchShopCart(headers?: Record<string, string | number>) {
            const { get: fetchCart } = useOFetchCustom("shop-cart-data")
            this.loadingShopCart = true

            fetchCart(undefined, {
                onSuccess: ({ data }) => {
                    this.shopCart = data
                    this.loadingShopCart = false
                },
                onError: () => {
                    this.loadingShopCart = false
                },
                headers: headers,
            })
        },
        async addToCart(body: any): Promise<any> {
            const { post } = useOFetchCustom("cart")
            return new Promise((resolve, reject) => {
                post(body, {
                    onSuccess: (res: any) => {
                        this.fetchShopCart()
                        resolve(res)
                    },
                    onError: (error: any) => {
                        reject(error)
                    },
                })
            })
        },
        async updateToCart(cartItem: CartItem, quantity: number): Promise<any> {
            const { put } = useOFetchCustom(`cart/${cartItem.id}`)

            return new Promise((resolve, reject) => {
                put(
                    { quantity },
                    {
                        onSuccess: (res: any) => {
                            this.fetchShopCart()

                            resolve(res)
                        },
                        onError: (error: any) => {
                            reject(error)
                        },
                    }
                )
            })
        },
    },
})
